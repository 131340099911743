<template>
  <v-card class="pa-4" flat>
    <v-tabs v-model="tab" color="primary" center-active @change="tabValue">
      <v-tab
        ><v-icon class="pr-2">mdi-account-check</v-icon>
        {{ $t("master_data.staff.tab1_title") }}</v-tab
      >
      <v-tab
        ><v-icon class="pr-2">mdi-account-remove</v-icon>
        {{ $t("master_data.staff.tab2_title") }}</v-tab
      >
      <v-tab
        ><v-icon class="pr-2">mdi-account-plus</v-icon>
        {{ $t("master_data.staff.tab3_title") }}</v-tab
      >
    </v-tabs>

    <v-tabs-items v-model="tab" touchless>
      <v-tab-item :touchless="true">
        <Data type="staff" />
        <AddData
          :title="$t('master_data.staff.dialog_1.title')"
          :content1="$t('master_data.staff.dialog_1.content_1')"
          :content2="$t('master_data.staff.dialog_1.content_2')"
          path="staff-manual"
          type="staff"
        />
      </v-tab-item>
      <v-tab-item :touchless="true">
        <Mutation :namaMenu="$t('app.staff')" menuType="staff" />
      </v-tab-item>
      <v-tab-item :touchless="true">
        <Candidate
          :namaMenu="$t('master_data.staff.tab1_title')"
          menuType="staff"
          :dataContentDialog="[
            $t('master_data.staff.dialog.content_1'),
            $t('master_data.staff.dialog.content_2'),
            $t('master_data.staff.dialog.content_3')
          ]"
          :queryData="queryCandidate"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/i18n";
export default {
  metaInfo: {
    title: `Master ${i18n.t("routes.staff")}`,
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    AddData: () => import("../components/AddData"),
    Data: () => import("../components/Data"),
    Mutation: () => import("../components/Mutation"),
    Candidate: () => import("../components/Candidate")
  },
  methods: {
    tabValue(tab) {
      this.$router.push({ name: "Staff", query: { tab: tab } });
    }
  },
  data() {
    return {
      tab: this.$route.query.tab | 0,
      queryCandidate: {
        page: 1,
        limit: 10,
        sort: ["name"],
        order: "DESC",
        search: "",
        type: "STAFF",
        status: "UNPROCESSED"
      },
      queryData: {
        page: 1,
        limit: 10,
        sort: ["id"],
        order: "DESC",
        search: "",
        institution: "",
        filter: {
          "employees.type": ["STAFF"],
          "employees.status": []
        }
      }
    };
  },
  watch: {
    "$route.query.tab"(n) {
      if (n) this.tab = this.$route.query.tab;
    }
  },
  computed: mapGetters([
    "g_master_data_staff",
    "g_master_data_dummy",
    "g_master_prospective_staff"
  ])
};
</script>
